import React, { useState, useEffect } from 'react';
import { ChevronDown, Moon, Sun } from 'lucide-react';

const CloudDevWebsite = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`min-h-screen font-sans ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      {/* Header */}
      <header className={`fixed w-full ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md transition-all duration-300 z-10`} style={{ transform: `translateY(-${Math.min(scrollPosition * 0.5, 50)}px)` }}>
        <div className="container mx-auto px-6 py-3 flex justify-between items-center">
          <img src="/clouddevword.png" alt="CloudDev" className="h-8" />
          <nav className="flex items-center">
            <ul className="flex space-x-4">
              <li><a href="#home" className="hover:text-blue-600 transition-colors">Home</a></li>
              <li><a href="#services" className="hover:text-blue-600 transition-colors">Services</a></li>
              <li><a href="#about" className="hover:text-blue-600 transition-colors">About</a></li>
              <li><a href="https://www.arcmutate.com/about/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600 transition-colors">Team</a></li>
            </ul>
            <button onClick={toggleDarkMode} className="ml-4 p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
              {darkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section id="home" className={`pt-24 pb-12 ${darkMode ? 'bg-gradient-to-b from-gray-800 to-gray-900' : 'bg-gradient-to-b from-[#1f4396] to-[#55c7f3]'} text-white`}>
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-4">CloudDev: we create things</h2>
          <p className="text-xl mb-8">Creators of ArcMutate and PiTest</p>
          <a href="#logo" className="inline-block animate-bounce">
            <ChevronDown size={32} />
          </a>
        </div>
      </section>

      {/* Logo Section */}
      <section id="logo" className={`py-12 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="container mx-auto px-6 text-center">
          <img src="/CloudDevM.png" alt="CloudDev Logo" className="mx-auto max-w-full h-auto" style={{maxHeight: '200px'}} />
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className={`py-16 ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md transition-transform hover:scale-105`}>
              <h3 className="text-xl font-semibold mb-2">Mutation Testing</h3>
              <a href="https://www.arcmutate.com" target="_blank" rel="noopener noreferrer" className="block mb-4">
                <img src={darkMode ? "/arcmutatedark.png" : "/arcmutate2.png"} alt="ArcMutate Logo" className="mx-auto max-w-full h-auto" style={{maxHeight: '300px'}} />
              </a>
              <p>Our ArcMutate and PiTest tools ensure your software has fewer bugs, increased security, and improved resilience.</p>
            </div>
            <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md transition-transform hover:scale-105`}>
              <h3 className="text-xl font-semibold mb-2">M&A Technology</h3>
              <a href="https://www.arcmutate.com" target="_blank" rel="noopener noreferrer" className="block mb-4">
                <img src="/creationdigital3.png" alt="creation.digital" className="mx-auto max-w-full h-auto" style={{maxHeight: '300px'}} />
              </a>
              <p>We're developing innovative solutions for secure and private data analysis in the M&A space.</p>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className={`py-16 ${darkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8 text-center">About CloudDev</h2>
          <div className={`${darkMode ? 'bg-gray-700' : 'bg-white'} p-8 rounded-lg shadow-md`}>
            <p className="mb-4">CloudDev are the owners and originators of ArcMutate and the inventors of PiTest. Mutation testing means software has less bugs and increases security and resilience.</p>
            <p className="mb-4">We are also developing innovative technology in the M&A space, using different forms of data analysis and AI to crunch data in a private and secure way, so you can get quick and accurate view of your own documents easily.</p>
            <a href="https://www.arcmutate.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Learn more about ArcMutate</a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className={`${darkMode ? 'bg-gray-900' : 'bg-gray-800'} text-white py-8`}>
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 The Cloud Development Group. All rights reserved. Company Registration Number: 14580536</p>
        </div>
      </footer>
    </div>
  );
};

export default CloudDevWebsite;